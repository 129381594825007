__webpack_public_path__ = "http://dev.local:8080/assets/";
import "../scss/main.scss";

import $ from 'jquery'
var imagesLoaded = require('imagesloaded');

// provide jQuery argument
imagesLoaded.makeJQueryPlugin( $ );

var hash = window.location.hash;

var target = window.location.hash,
  target = target.replace('#', '');

// delete hash so the page won't scroll to it
window.location.hash = "";


$(document).ready(function () {
  /*  console.log('js works');*/
  var ww, wh, leftItem;

  const links = document.querySelectorAll("a");
  links.forEach((link) => {
    if (link.target) {
      return;
    } else if (link.host !== window.location.host) {
      link.target = "_blank";
      link.rel = "noopener";
    } else {
      link.target = "_self";
    }
  });










  $(".page").scroll(function () {
    /*console.log("scrollll");*/
  });


  var currentScroll = 0;


  $(".page").scroll(function () {
    currentScroll = $(this).scrollTop();
  });


  if (target) {

    var AP = $(".submenu-right ul li:first-of-type h2 a").attr('href');
    var APtarget = AP.substr(AP.indexOf("#"));


    var nav_height = $('nav').height();

    if((currentScroll == 0) && (APtarget == "#"+target) ){

    }else {

      setTimeout(function () {

        $('.page').animate({
          scrollTop: $("#" + target).offset().top - nav_height
        }, 700, 'swing', function () {

        });
      }, 500);
    }

  }

  $(".a-propos .scroll-link").click(function (e) {

    $('.dropdown-menu').removeClass("show");
    $('.dropdown').removeClass("show");

    AP = $(".submenu-right ul li:first-of-type h2 a").attr('href');
    APtarget = $(AP.substr(AP.indexOf("#")));


    nav_height = $('nav').height();
    e.preventDefault();

    var href = $(this).attr("href");
    target = $(href.substr(href.indexOf("#")));


    if((href == AP) && (currentScroll == 0)) {



    }else{

      if(href == AP){

        $('.page').animate({scrollTop: 0}, 700, 'swing');

      }else{
        $('.page').animate({
          scrollTop: target.offset().top + currentScroll - nav_height
        }, 700, 'swing');
      }



    }


  });

  $('.scroll-link').click(function () {
    $(".navbar-collapse").collapse('hide');
  });


  var mySwiper = new Swiper('.swiper-container', {
    // Optional parameters
    zoom: {
      maxRatio: 1,
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 2,
    },
    loop: true,
    simulateTouch: false,
    allowTouchMove: false,
    effect: "slide",

    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    speed: 0,
    preventInteractionOnTransition: true,
    watchOverflow: true,
  });


  $('.tiny-cross').click(function () {

    $(this).parent().fadeOut(100)

  });


  // NEWSLETTER FORM


  $('.submit').click(function () {

    $(this).closest('form').submit();

    $(this).find("span").fadeIn(100);

    setTimeout(function () {

      $(this).find("span").fadeIn(100);

    }, 300);

  });


  // COOKIES WARNING AND BANNER


  var formData = {
    set: function () {

      /* console.log("data1=" + $(".cookies-data").attr("data-cookies"));*/
      // (Re)create the myForm valiable
      var myForm = "";
      // Delete old data from localstorage
      localStorage.removeItem('myForm');

      if ($(".cookies-data").attr("data-cookies") == "true") {
        myForm = true;
      } else {
        myForm = false;
      }
      // Add the array to localStorage
      localStorage.myForm = JSON.stringify(myForm);

      /*     console.log(myForm);*/
    },

    get: function () {

      /* console.log("LS=" + localStorage.myForm);*/
      // Is the form already stored within localStorage? If so, get it and copy it's contents over our myform array variable.
      if (localStorage.myForm == "true") {
        /*  console.log("wrong");*/
        // Get the existing values out of localStorage
        var myForm = JSON.parse(localStorage.myForm);
        $('.cookies-data').attr("data-cookies", myForm);
      } else {
        setTimeout(function () {

          $('.cookies').fadeIn(100).css("display", "flex");

        }, 400);
      }

    }
  }

  // Populate the form with whatever data already exists for it
  formData.get();
  formData.set();

  setTimeout(function () {
    $('.warning-banners').fadeIn(100);
  }, 3000);


  $(".cookies .tiny-cross").on('click', function () {
    $('.cookies-data').attr("data-cookies", "true");
    /* console.log("data2=" + $(".cookies-data").attr("data-cookies"));*/
    formData.set();
    $('.cookies').fadeOut(100);
  });


  $(window).resize(function () {

   var ww = $(window).width();
   var wh = $(window).height();
   var hh = $('nav').height();

    setTimeout(function () {
      SeemoreTitleResize(ww);
    },200)

    SetMenuSize(ww, wh, hh);
    CalendarBookButton(ww);
    DropDownMenu(ww);
    /* console.log("window");*/
  });

  $(window).trigger('resize');


  // STICKY EVENT DATES

  if ($('.swiper-container').length && $('.dates-sticky').length) {


    var Reach_carousel = $('.swiper-container').offset().top - $('.navbar').height();
    var Reach_seemore = $('.seemore').offset().top - $('.navbar').height() * 2;

    $(".page ").scroll(function () {
      /*console.log("scrollll");*/
      if ($(this).scrollTop() >= Reach_carousel && $(this).scrollTop() <= Reach_seemore) {
        $(".dates-sticky").addClass("show");
      } else {
        $(".dates-sticky").removeClass("show");
      }
    });
  }


  // MAILCHIMP FORM

  $('input[name="subscribe"]').click(function (e) {
    e.preventDefault();
  });


  // CALENDAR MENU

  $('.calendar-menu a').click(function (e){
    $('.calendar-grid-item-infos .button').hide();
    e.preventDefault();
    $('.calendar-menu a').removeClass('active-parent');
    $(this).addClass("active-parent");
    CalendarBookButton(ww);
    $('.calendar-grid-item-infos .button').show();

    target = $(this).attr('href');

    $('.calendar-section').css('display',"none");
    $(target).css("display","block");

  });


  // SHOW ONGOING SEASON PAST EVENTS
  var default_target = $('.past-events--submenu a:first-of-type').attr('data-target');
  $('#past-events .calendar-grid-item[data-season="'+default_target+'"]').removeClass('hide');


  $('.past-events--submenu a').click(function (e){

    $('.past-events--submenu a').removeClass('active');
    $(this).addClass("active");

    target = $(this).attr('data-target');
    $('#past-events .calendar-grid-item').addClass('hide');
    $('#past-events .calendar-grid-item[data-season="'+target+'"]').removeClass('hide');

  });





  CalendarBookButton(ww);

});


function SetMenuSize(ww, wh, hh) {


  if (ww < 768) {
    var mh = wh - hh;

    $('.navbar-collapse .adjust-height').css("height", mh);
  } else {
    $('.navbar-collapse .adjust-height').css("height", "");
  }
}

function SeemoreTitleResize(ww) {

  if (ww > 768) {

    /*console.log("trigger resize");*/

    $('.seemore-grid-item').find('.seemore-grid-item-infos-wrap').css("height", "");

    $('.seemore-grid-item.left').each(function () {

      var leftItem = $(this);
      var rightItem = $(this).next(".right");


      // COMPARE TITLE SIZE

      var leftItemTitleSize = leftItem.find(".seemore-grid-item-infos-wrap").height();
      var rightItemTitleSize = rightItem.find(".seemore-grid-item-infos-wrap").height();


      if (rightItemTitleSize > leftItemTitleSize) {
        leftItem.find('.seemore-grid-item-infos-wrap').css("height", rightItemTitleSize);
      } else {
        rightItem.find('.seemore-grid-item-infos-wrap').css("height", leftItemTitleSize);
      }


      /*console.log("leftItem" + leftItem.find('h2').html());
      console.log("rightItem" + rightItem.find('h2').html());*/

    });
  } else {
    $('.seemore-grid-item').find('.seemore-grid-item-infos-wrap').css("height", "");
  }
}

function CalendarBookButton(ww) {

  $('body').imagesLoaded( function() {

    if (ww < 768) {



      $(".calendar-grid-item").each(function () {

        var distance_top = $(this).find(".calendar-grid-item-infos").outerHeight(true);
        var button = $(this).find(".book");
        /*console.log(distance_top);*/

        button.css("padding-top", distance_top);
        button.find("button").css("opacity", "1");
      });



    }else{

      $(".calendar-grid-item").each(function () {

        var button = $(this).find(".book");

        button.css("padding-top", "");

      });

    }

  });

}

function DropDownMenu(ww) {

  $('.dropdown').removeClass("show");
  $('.dropdown-menu').removeClass("show");



  if (ww > 768) {
    $('.dropdown').click(function () {
      $('.dropdown').removeClass("show");
      $('.dropdown-menu').removeClass("show");
      $(this).toggleClass("show");
      $(this).find('.dropdown-menu').first().toggleClass("show");
    }, function () {
    });

    $('.dropdown').unbind( "click");
  }


  if (ww > 768) {
    $('main').click(function () {
      $('.dropdown-menu').removeClass("show");
      $('.dropdown').removeClass("show");
    });
  }

  if (ww < 768) {
    $('.dropdown').unbind('mouseenter mouseleave');
    $(".active-parent").parent().addClass("show");
    $(".active-parent").parent().find('.dropdown-menu').addClass("show");

    $('.dropdown').bind( "click", function() {
      $('.dropdown').removeClass("show");
      $('.dropdown-menu').removeClass("show");
    });
  }


}




